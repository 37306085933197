import React from 'react';
import SettingsFormControl from '../../common/components/settings/SettingsFormControl';
import MainContainer from '../../common/components/MainContainer';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import PropTypes from 'prop-types';
import ActionBar from '../../quiz/components/ActionBar';
import SettingsBox from '../../common/components/settings/SettingsBox';
import Grid from '@mui/material/Grid2';
import {Divider, Typography} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import LiveStreamPicker from '../../common/components/settings/livestream/LiveStreamPicker'
import {getLiveStreamChannelType} from '../../common/utils/livestream';

export default function SettingsTab(props) {
	const model = props.model;
	const onChange = props.onChange;
	const onSubmit = props.onSubmit;
	const channelType = getLiveStreamChannelType(model.livestream, props.channels);
	
	return (
		<ValidatorForm noValidate autoComplete='off' onSubmit={onSubmit} style={{marginTop: 0}}>
			<ActionBar saving={props.saving} type={'submit'}/>
			<MainContainer>
				<Grid spacing={3} container>
					<Grid size={6}>
						<SettingsBox title={'Broadcast Settings'}>
							<SettingsFormControl>
								<TextValidator
									errorMessages={['Broadcast name is required']}
									fullWidth
									label='Name'
									onChange={e => onChange('name', e.target.value)}
									required
									validators={['required']}
									value={model.name}
									variant='outlined'
								/>
							</SettingsFormControl>
							
							<SettingsFormControl>
								<TextValidator
									errorMessages={['Source URL is required', 'Invalid URL']}
									fullWidth
									label='Source URL'
									onChange={e => onChange('srcUrl', e.target.value)}
									required
									validators={['required', 'matchRegexp:^https?:\\/\\/(\\w+:{0,1}\\w*@)?(\\S+)(:[0-9]+)?(\\/|\\/([\\w#!:.?+=&%@!\\-/]))?$']}
									value={model.srcUrl}
									variant='outlined'
								/>
							</SettingsFormControl>
							
							<LiveStreamPicker
								channels={props.channels}
								deleteBtn={false}
								onChange={newValue => onChange('livestream', newValue)}
								onError={props.onError}
								livestream={model.livestream}
							/>
							
							{model.livestream && <>
								{channelType === 'youtube' && <SettingsFormControl variant={'outlined'}>
									<InputLabel style={{backgroundColor: '#fff'}}>YouTube Server</InputLabel>
									<Select
										onChange={e => onChange('livestream.serverType', e.target.value)}
										required
										value={model.livestream.serverType || 'primary'}
									>
										<MenuItem value={'primary'}>Primary Server</MenuItem>
										<MenuItem value={'backup'}>Backup Server</MenuItem>
									</Select>
								</SettingsFormControl>}
								
								{channelType === 'twitch' && <SettingsFormControl variant={'outlined'}>
									<InputLabel style={{backgroundColor: '#fff'}}>Twitch Stream Type</InputLabel>
									<Select
										onChange={e => onChange('livestream.streamType', e.target.value)}
										required
										value={model.livestream.streamType || 'normal'}
									>
										<MenuItem value={'normal'}>Normal</MenuItem>
										<MenuItem value={'test'}>Test</MenuItem>
									</Select>
								</SettingsFormControl>}
							</>}
							
							<SettingsFormControl variant={'outlined'}>
								<InputLabel required style={{backgroundColor: '#fff'}}>Quality</InputLabel>
								<Select
									onChange={e => onChange('quality', e.target.value)}
									required
									value={model.quality || 'high'}
								>
									<MenuItem value={'low'}>Low</MenuItem>
									<MenuItem value={'medium'}>Medium</MenuItem>
									<MenuItem value={'high'}>High</MenuItem>
								</Select>
							</SettingsFormControl>
						
						</SettingsBox>
					</Grid>
					<Grid size={6}>
						<SettingsBox title={'Broadcast Information'} className={'full-height'}>
							<Typography variant='body2' mt={0.5}>
								Your source will be streamed to YouTube with the following properties:
							</Typography>
							<Typography mt={1.5}><strong>Resolution:</strong> 1280x720</Typography>
							<Typography mt={1}><strong>FPS:</strong> 30</Typography>
							<Typography mt={1}><strong>Video bitrate</strong>: 2500 Kbps</Typography>
							<Typography mt={1} mb={2}><strong>Audio bitrate</strong>: 128 Kbps</Typography>
							<Divider/>
							<Typography variant='body2' mt={2} component='div'>
								<strong>IMPORTANT:</strong>
								<ul>
									<li>It takes around 2 minutes for the server to start. Please plan live streaming accordingly.</li>
									<li>If the audio is not in sync, pause the stream server for a few seconds and then resume. This should resolve the audio issue.</li>
								</ul>
							</Typography>
						</SettingsBox>
					</Grid>
				</Grid>
			</MainContainer>
		
		</ValidatorForm>
	)
}

SettingsTab.propTypes = {
	model: PropTypes.object.isRequired
}
