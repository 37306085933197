import React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTwitch, faYoutube} from '@fortawesome/free-brands-svg-icons';
import {useNavigate} from 'react-router-dom';

export default function AddChannelButton() {
	
	const btnId = 'add-channel-button';
	const menuId = 'basic-menu';
	
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	
	return (
		<div className={'action-btn'}>
			<Button
				id={btnId}
				aria-controls={open ? menuId : undefined}
				aria-haspopup={'true'}
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				variant={'contained'}
			>
				Add a channel
			</Button>
			<Menu
				id={menuId}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{'aria-labelledby': btnId}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<MenuItem onClick={() => navigate('/youtube/connect/')}>
					<ListItemIcon>
						<FontAwesomeIcon icon={faYoutube}/>
					</ListItemIcon>
					YouTube
				</MenuItem>
				<MenuItem onClick={() => navigate('/twitch/connect/')}>
					<ListItemIcon>
						<FontAwesomeIcon icon={faTwitch}/>
					</ListItemIcon>
					Twitch
				</MenuItem>
			</Menu>
		</div>
	);
}
