import React from 'react';
import MainContainer from '../../common/components/MainContainer';
import {ValidatorForm} from 'react-material-ui-form-validator';
import PropTypes from 'prop-types';
import ActionBar from '../../quiz/components/ActionBar';
import Grid from '@mui/material/Grid2';
import PosterBox from '../../common/components/settings/PosterBox';
import GeneralBox from '../boxes/GeneralBox';
import LiveStreamsSettingsBox from '../../common/components/settings/livestream/LiveStreamsSettingsBox';

export default function SettingsTab(props) {
	const model = props.model;
	const onSubmit = props.onSubmit;
	
	return (
		<ValidatorForm noValidate autoComplete='off' onSubmit={onSubmit} style={{marginTop: 0}}>
			<ActionBar saving={props.saving} type={'submit'}/>
			<MainContainer>
				<Grid container className={'poll-settings-tab'} spacing={3} direction='row'>
					<Grid size={6}>
						<Grid container direction='column' spacing={3}>
							<Grid item><GeneralBox model={model} onChange={props.onChange}/></Grid>
							<Grid item><PosterBox model={model} onChange={props.onChange}/></Grid>
						</Grid>
					</Grid>
					<Grid size={6}>
						<LiveStreamsSettingsBox
							channels={props.channels}
							disabled={['running', 'starting'].includes(model.status)}
							livestreams={model.livestreams}
							onChange={livestreams => props.onChange('livestreams', livestreams)}
							onError={props.onError}
						/>
					</Grid>
				</Grid>
			</MainContainer>
		
		</ValidatorForm>
	
	)
}

SettingsTab.propTypes = {
	model: PropTypes.object.isRequired
}
