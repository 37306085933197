import React, {useEffect, useState} from 'react';
import api from '../common/ApiRequest';
import PagePreloader from '../common/components/PagePreloader';
import Notification from '../common/components/Notification';
import MainContainer from '../common/components/MainContainer';
import Grid from '@mui/material/Grid2';
import {Box, Paper, Typography} from '@mui/material';
import Status from './components/Status';
import {Link, useNavigate, useParams} from 'react-router-dom';
import ClipboardButton from '../common/buttons/ClipboardButton';
import StartStopButton from './buttons/StartStopButton';
import Scale from '../common/components/Scale';
import TogglePreviewButton from '../common/buttons/TogglePreviewButton';
import VideoCamIcon from '@mui/icons-material/Videocam';
import RebootButton from './buttons/RebootButton';
import StreamLink from '../common/components/StreamLink';
import PauseResumeButton from './buttons/PauseResumeButton';
import BroadcastTabs from './components/BroadcastTabs';
import AppBar from '@mui/material/AppBar';
import {getLiveStreamURL} from '../common/utils/livestream';
import {capitalize} from '../common-libs/utils/String';

export default function BroadcastControlRoom() {
	const navigate = useNavigate()
	const params = useParams();
	const [message, setMessage] = useState(null);
	const [model, setModel] = useState(null);
	const [loading, setLoading] = useState(true);
	const [iframeVisible, setIframeVisible] = useState(true);
	
	/**
	 * Load broadcast status every 10 seconds.
	 */
	useEffect(() => {
		const interval = setInterval(() => setLoading(true), 10000);
		return () => clearInterval(interval);
	}, []);
	
	/**
	 * Update broadcast data.
	 */
	useEffect(() => {
		if (loading) {
			setLoading(false);
			(async () => {
				try {
					const broadcast = await api.get(`/apps/broadcast/${params.broadcastId}`);
					setModel(broadcast);
				} catch (e) {
					setMessage(e.toString());
				}
			})();
		}
	}, [loading, params.broadcastId]);
	
	return <div className={'app-control-room broadcast'}>
		{!model ?
			<PagePreloader error={message}/> :
			<>
				<Notification
					message={message}
					severity={'error'}
					onClear={() => setMessage(null)}
				/>
				
				<MainContainer>
					<AppBar position={'sticky'} color='default' className={'app-bar'}>
						<BroadcastTabs onClick={path => navigate(`/broadcast/${params.broadcastId}/${path}`)} selected={'control-room'}/>
					</AppBar>
					
					<Grid container spacing={2}>
						<Grid size={3}>
							
							<Box mb={1}>
								<Status model={model}/>
							</Box>
							
							{model.status === 'running' &&
								<Box mt={1} mb={1}>
									<Paper className={'sidebar'}>
										<Typography color={'secondary'} className={'streaming'} variant='button'><VideoCamIcon fontSize='small'/> Live Streaming</Typography>
									</Paper>
								</Box>
							}
							
							<Paper className={'sidebar'}>
								<Typography variant='subtitle2' color='primary' className={'title'}>Broadcast</Typography>
								<Box mb={2.5}>
									<Link to={`/broadcast/${model._id}`}>
										<Typography variant='body2'>{model.name}</Typography>
									</Link>
								</Box>
								
								<Typography variant='subtitle2' color='primary' className={`title icon-title`}>
									Source URL
									<ClipboardButton value={model.srcUrl}/>
								</Typography>
								
								<Box mb={2.5}>
									<a href={model.srcUrl} target={'_blank'} rel='noreferrer' className={'ellipsis'}>
										<Typography variant='body2'>{model.srcUrl}</Typography>
									</a>
								</Box>
								
								<Typography variant='subtitle2' color='primary' className={`title icon-title`}>
									Live Stream
									{model.livestream.ytStreamId && <ClipboardButton value={getLiveStreamURL(model.livestream)}/>}
								</Typography>
								<Box mb={2.5}>
									<StreamLink livestream={model.livestream}/>
								</Box>
								
								<Typography variant='subtitle2' color='primary' className={'title'}>Server</Typography>
								<Box mb={2.5}>
									<Typography variant='body2'>{model.serverType === 'backup' ? 'Backup' : 'Primary'} Server</Typography>
								</Box>
								
								<Typography variant='subtitle2' color='primary' className={'title'}>Quality</Typography>
								<Box mb={2.5}>
									<Typography variant='body2'>{capitalize(model.quality || 'high')}</Typography>
								</Box>
							</Paper>
						</Grid>
						
						<Grid size={9}>
							<Paper className={'paper'}>
								<TogglePreviewButton
									visible={iframeVisible}
									onToggle={visible => setIframeVisible(visible)}
								/>
								
								{['running', 'paused'].includes(model.status) && <RebootButton
									appId={model._id}
									disabled={model.status !== 'running'}
									onError={e => setMessage(e.toString())}
								/>}
								
								<PauseResumeButton
									app={model}
									onError={e => setMessage(e.toString())}
									onChange={() => setLoading(true)}
								/>
								
								<StartStopButton
									app={model}
									onError={e => setMessage(e.toString())}
									onChange={() => setLoading(true)}
								/>
							</Paper>
							
							<Box mt={2}>
								{iframeVisible && (
									<Scale selector={'iframe'} className={'preview'}>
										<iframe
											title={'preview'}
											className={`iframe`}
											src={`${model.srcUrl}`}
										></iframe>
									</Scale>
								)}
							</Box>
						</Grid>
					</Grid>
				</MainContainer>
			</>
		}
	</div>;
}
