import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Status from './Status';
import {Tooltip, Typography} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {Link} from 'react-router-dom';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import EditIcon from '@mui/icons-material/Edit';
import TableContainer from '@mui/material/TableContainer';
import React from 'react';
import PropTypes from 'prop-types';
import DeleteButton from '../../common/buttons/DeleteButton'
import authService from '../../auth/AuthService';
import LivestreamsTableCell from '../../common/components/table/LivestreamsTableCell';

export default function BroadcastTable({component = Paper, ...props}) {
	const apps = props.apps;
	const user = authService.getCurrentUser();
	
	return (
		<TableContainer component={component}>
			<Table aria-label='My Broadcasts' className={'app-table'}>
				<TableHead>
					<TableRow>
						<TableCell>Name</TableCell>
						{user.role !== 'creator' && <TableCell>User</TableCell>}
						<TableCell>Source</TableCell>
						<TableCell>Destination</TableCell>
						<TableCell align={'center'}>Status</TableCell>
						<TableCell align='center'>Actions</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{(apps && apps.length === 0) && <TableRow>
						<TableCell colSpan={user.role !== 'creator' ? 6 : 5}><Typography align={'center'}>Nothing found</Typography></TableCell>
					</TableRow>}
					{apps && apps.map(app => (
						<TableRow key={app._id}>
							<TableCell component='th' scope='row'>
								{app.name}
							</TableCell>
							{user.role !== 'creator' && <TableCell>{app.user && app.user.name}</TableCell>}
							<TableCell>
								<a href={app.srcUrl} target={'_blank'} rel={'noreferrer'}>{app.srcUrl}</a>
							</TableCell>
							<LivestreamsTableCell livestreams={[app.livestream]}/>
							<TableCell className={'status-cell'}>
								<Status model={app}/>
							</TableCell>
							<TableCell>
								<div className={'actions'}>
									<Tooltip title={'Control Room'}>
												<span>
													<IconButton
														aria-label='control-room'
														color='secondary'
														component={Link}
														to={`/broadcast/${app._id}/control-room`}
														size='small'
													>
														<SettingsRemoteIcon fontSize={'small'}/>
													</IconButton>
												</span>
									</Tooltip>
									<Tooltip title={'Edit'}>
										<IconButton
											aria-label='edit'
											color='primary'
											component={Link}
											to={`/broadcast/${app._id}`}
											size='small'>
											<EditIcon fontSize={'small'}/>
										</IconButton>
									</Tooltip>
									
									<DeleteButton
										apiUrl={`/apps/broadcast/${app._id}`}
										message={'Are you sure you want to delete this broadcast? This action is irreversible.'}
										onError={props.onDeleteError}
										onSuccess={props.onDeleteSuccess}
									/>
								</div>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

BroadcastTable.propTypes = {
	apps: PropTypes.array,
	component: PropTypes.any,
	onDeleteError: PropTypes.func,
	onDeleteSuccess: PropTypes.func
}
