import React, {useCallback, useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import api from '../../common/ApiRequest';
import {Box, LinearProgress, Tooltip} from '@mui/material';
import {Link} from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import DeleteButton from '../../common/buttons/DeleteButton';
import Notification from '../../common/components/Notification';
import {useQueryParams} from '../../common/utils/Search';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FilterBar from '../components/FilterBar';
import ChannelLink from '../../channel/components/ChannelLink';
import moment from 'moment';

export default function UserList() {
	
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [users, setUsers] = useState(null);
	const [pagination, setPagination] = useState(null);
	const [searchParams, setSearchParams] = useQueryParams();
	
	const loadData = useCallback(() => {
		setLoading(true);
		api
			.get(`/user/`, searchParams)
			.then(result => {
				setUsers(result.data);
				setPagination(result.pagination);
			})
			.catch(e => setError(e.toString()))
			.finally(() => setLoading(false));
	}, [searchParams]);
	
	useEffect(() => {
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
		loadData();
	}, [loadData]);
	
	return (
		<div className={'app-list'}>
			
			<FilterBar loading={loading}/>
			
			<Box sx={{width: '100%'}} style={{visibility: loading ? 'visible' : 'hidden'}}> <LinearProgress/> </Box>
			{!!(users && users.length) && (<Paper>
					<TableContainer component={Paper}>
						<Table aria-label='Users' className={'app-table'}>
							<TableHead>
								<TableRow>
									<TableCell>Name</TableCell>
									<TableCell>Email</TableCell>
									<TableCell>Role</TableCell>
									<TableCell>Channels</TableCell>
									<TableCell>Added On</TableCell>
									<TableCell align={'center'}>Active</TableCell>
									<TableCell align={'right'}>Actions</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{users.map(u => (
									<TableRow key={u._id}>
										<TableCell>{u.name}</TableCell>
										<TableCell><a href={`mailto:${u.email}`}>{u.email}</a></TableCell>
										<TableCell className={u.role}>{u.role}</TableCell>
										<TableCell className={'channels'}>
											{u.channels.map(c => <ChannelLink key={`${c.socialId}`} channel={c}/>)}
										</TableCell>
										<TableCell>{u.createdAt && moment(Date.parse(u.createdAt)).calendar()}</TableCell>
										<TableCell align={'center'}>
											{u.active ? <CheckCircleOutlineIcon color={'success'}/> : <BlockIcon color={'secondary'}/>}
										</TableCell>
										<TableCell align={'right'}>
											<Tooltip title={'Edit'}>
												<IconButton
													aria-label='edit'
													color='primary'
													component={Link}
													to={`/user/${u._id}`}
													size='large'>
													<EditIcon/>
												</IconButton>
											</Tooltip>
											
											<DeleteButton
												apiUrl={`/user/${u._id}`}
												message={'Are you sure you want to delete this user? This action is irreversible.'}
												onError={e => setError(e.toString())}
												onSuccess={() => loadData()}
											/>
										
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					
					{(pagination && pagination.totalPages > 1) && <Pagination
						className={'pagination'}
						size={'large'}
						color={'primary'}
						page={pagination.page}
						count={pagination.totalPages}
						renderItem={(item) => <PaginationItem
							{...item}
							onClick={() => setSearchParams('page', item.page)}
						/>
						}
					/>}
				</Paper>
			)}
			
			<Notification severity={'error'} message={error} onClear={() => setError(null)}/>
		</div>
	);
}
