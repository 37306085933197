import React from 'react';
import SocialIcon from '../../common/components/SocialIcon';

export default function ChannelLink({channel, className = ''}) {
	return <div className={`${className} channel-link`}>
		<a href={channel.url} target={'_blank'} rel={'noreferrer'}>
			<SocialIcon type={channel.socialType} size={'md'}/>
			{channel.title}
		</a>
	</div>
}
